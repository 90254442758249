import React from 'react';
// import { siteMetadata } from '../../data/siteMetadata';

const WebBanner = () => {

    const logos = [
        { src: '/images/blog/java.png' },
        { src: '/images/blog/python.png' },
        { src: '/images/blog/c-sharp.svg' },
        { src: '/images/blog/node-js.svg' },
        { src: '/images/blog/aws.png' },
        { src: '/images/blog/golang.svg' },
        { src: '/images/blog/terraform.svg' },
        { src: '/images/blog/js.png' },
        { src: '/images/blog/cockroachdb.svg' },
        { src: '/images/blog/react.png' },
        { src: '/images/blog/docker.svg' },
        { src: '/images/blog/mongodb.png' },
        { src: '/images/blog/gcp.svg' },
        { src: '/images/blog/vscode.png' },
        { src: '/images/blog/flink.png' },
        { src: '/images/blog/github.svg' },
    ];
    return (
        <div className="bg-black banner">
            <div className="py-2">
                <div className="slider">
                    <div className="slide-track">
                        {
                            logos.map(() => {
                                return (
                                    <div className="slide">
                                        {/* <img
                                            src={`${siteMetadata.siteUrl}${logo.src}`}
                                            alt={logo.alt}
                                            className="lang-logo"
                                        /> */}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebBanner;
