import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = ({ title, description, URL }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:url" content={URL} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="article" />
            <meta property="twitter:url" content={URL} />
            <meta property="twitter:description" content={description} />
            <link rel="canonical" href={URL} />
        </Helmet>
    );
};

export default HelmetComponent;
