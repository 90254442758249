import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import { siteMetadata } from '../../data/siteMetadata';
import headerNavLinks from '../../data/headerNavLinks';
import SectionContainer from '../section_container/SectionContainer';
import Footer from '../footer/Footer';
import MobileNav from '../mobile_nav/MobileNav';
import ThemeSwitch from '../theme_switch/ThemeSwitch';
import { ReactComponent as Logo } from '../../data/logo.svg';
import WebBanner from '../web-banner/WebBanner';
import { ReactGAEvents } from '../../utils/Constants';


const LayoutWrapper = ({ children }) => {
    return (
        <>
            <WebBanner />
            <SectionContainer>
                <div className="flex flex-col justify-between h-screen">
                    <header className="flex items-center justify-between py-10">
                        <div>
                            <NavLink
                                to="/"
                                onClick={() => {
                                    ReactGA.event({
                                        category: ReactGAEvents.InternalLink,
                                        action  : 'Clicked Internal Link /',
                                    });
                                }}
                                aria-label="tallmarmaladeporpoise's blog"
                                className="hover:no-underline"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="mr-3">
                                        <Logo />
                                    </div>
                                    {typeof siteMetadata.headerTitle === 'string' ? (
                                        <div className="hover:text-primary-500 hidden h-6 text-2xl font-semibold sm:block dark:text-gray-100">
                                            {siteMetadata.headerTitle}
                                        </div>
                                    ) : (
                                        siteMetadata.headerTitle
                                    )}
                                </div>
                            </NavLink>
                        </div>
                        <div className="flex items-center text-base leading-5">
                            <div className="hidden sm:block">
                                {headerNavLinks.map((link) => {
                                    return (
                                        <NavLink
                                            key={link.title}
                                            to={link.href}
                                            onClick={() => {
                                                ReactGA.event({
                                                    category: ReactGAEvents.InternalLink,
                                                    action  : `Clicked Internal Link ${link.href}`,
                                                });
                                            }}
                                            className="hover:no-underline hover:text-primary-600 font-medium text-gray-900 sm:p-4 dark:text-gray-100"
                                        >
                                            {link.title}
                                        </NavLink>
                                    );

                                })}
                            </div>
                            <ThemeSwitch />
                            <MobileNav />
                        </div>
                    </header>
                    <main className="mb-auto">{children}</main>
                    <Footer />
                </div>
            </SectionContainer>
        </>
    );
};

export default LayoutWrapper;
