import React from 'react';
import './dist/output.css';
import { BrowserRouter } from 'react-router-dom';
import { RouteDeclarations } from './routes/RouteDeclarations';
import { UIContextProvider } from './context/UIContext';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <UIContextProvider>
                <RouteDeclarations />
            </UIContextProvider>
        </BrowserRouter>
    );
};

export default App;
