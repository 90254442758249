import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import { siteMetadata } from '../../data/siteMetadata';
import { ReactGAEvents } from '../../utils/Constants';
// import SocialIcon from '../social-icons';

export const Footer: React.FC = () => {
    return (
        <footer>
            <div className="flex flex-col items-center mt-16">
                <div className="flex mb-3 space-x-4">
                    {/* <SocialIcon kind="mail" href={`mailto:${siteMetadata.email}`} size={6} />
                    <SocialIcon kind="github" href={siteMetadata.github} size={6} />
                    <SocialIcon kind="linkedin" href={siteMetadata.linkedin} size={6} />
                    <SocialIcon kind="twitter" href={siteMetadata.twitter} size={6} /> */}
                </div>
                <div className="flex mb-2 space-x-2 text-sm text-gray-500 dark:text-gray-400">
                    <div>{siteMetadata.author}</div>
                    <div>{' • '}</div>
                    <div>{`© ${new Date().getFullYear()}`}</div>
                    <div>{' • '}</div>
                    <NavLink
                        to="/"
                        onClick={() => {
                            ReactGA.event({
                                category: ReactGAEvents.InternalLink,
                                action  : 'Clicked Internal Link /',
                            });
                        }}
                    >{siteMetadata.title}
                    </NavLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
