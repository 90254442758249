import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (!process.env.REACT_APP_GA_TRACKING_ID) {
    throw new Error('Failed to render application. Missing REACT_APP_GA_TRACKING_ID.');
}

const trackingId = process.env.REACT_APP_GA_TRACKING_ID; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, { debug: false });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
