import { AmazonAffiliateLinks, Gadgets, UsefulServices } from '../utils/Constants';

export interface StoreFeatured {
    name: string,
    href: string,
    imageSrc: string,
    imageAlt: string,
    path: string,
}

export interface StoreItemBreadcrumb {
    id: number,
    name: string,
    href: string
}

export interface StoreItemImage {
    src: string,
    alt: string
}

export interface StoreItem {
    name: string,
    href: string,
    path: string,
    tags: string[],
    price: string,
    breadcrumbs: StoreItemBreadcrumb[],
    images: StoreItemImage[],
    description: string,
    highlights: string[],
    details: string,
}
export interface StoreSection {
    id: string,
    name: string,
    items: StoreItem[],
    breadcrumbs: StoreItemBreadcrumb[],
}

export interface StoreCategory {
    id: string,
    name: string,
    featured: StoreFeatured[],
    sections: StoreSection[],
}

export interface StorePage {
    name: string,
    href: string
}
export interface Store {
    categories: StoreCategory[],
    pages: StorePage[]
}

export const siteMetadata = {
    title       : 'tallmarmaladeporpoise\'s Website',
    author      : 'tallmarmaladeporpoise',
    headerTitle : '👋 🙇‍♂️',
    description : '',
    language    : 'en-us',
    theme       : 'system', // system, dark or light
    siteUrl     : 'https://tallmarmaladeporpoise.com', // http://localhost:3000
    siteRepo    : 'https://github.com/timlrx/tailwind-nextjs-starter-blog',
    siteLogo    : '/static/favicon.svg',
    image       : '/static/images/avatar.png',
    socialBanner: '/static/images/twitter-card.png',
    email       : 'mwong17@cmc.edu',
    github      : 'https://github.com/mattthewong',
    twitter     : 'https://twitter.com/mattthewong',
    linkedin    : 'https://www.linkedin.com/in/mattthewong',
    locale      : 'en-US',
    about       : {
        name          : 'tallmarmaladeporpoise',
        occupation    : 'Software Engineer',
        company       : 'LaunchDarkly',
        descriptionOne: 'Hey! I\'m Matt, nice to meet you. An ambitious engineer with over 7 years of software engineering experience, I specialize in designing and developing backend services and reliable infrastructure at scale. I\'ve helped drive engineering in small series A startups, scale later stage pre-IPO companies, as well as experience at large enterprises.',
        highlightText : '',
        hiringLink    : '',
        hiringFollowUp: '',
        passions      : 'I have expertise in across the software development lifecycle, and have successfully led several projects contributing to product feature, performance, security, and resiliency improvements of services. I\'ve found that I thrive in collaborative environments and have a passion for mentoring others while forever being a student of the craft myself.',
        contact       : 'Like this site or curious to get in contact? Feel free to drop me an email by clicking the icon underneath my profile.',
    },
    blog: {
        tagline: 'Hi there! Here you will find information about my experience with technology.',
    },
    lesson: {
        tagline: 'Hi there! Here you will find lessons related to software engineering.',
    },
    analytics: {
    // supports plausible, simpleAnalytics or googleAnalytics
        plausibleDataDomain: '', // e.g. tailwind-nextjs-starter-blog.vercel.app
        simpleAnalytics    : false, // true or false
        googleAnalyticsId  : '', // e.g. UA-000000-2 or G-XXXXXXX
    },
    newsletter: {
    // supports mailchimp, buttondown, convertkit, klaviyo
    // Please add your .env file and modify it according to your selection
        provider: 'buttondown',
    },
    comment: {
    // Select a provider and use the environment variables associated to it
    // https://vercel.com/docs/environment-variables
        provider    : 'giscus', // supported providers: giscus, utterances, disqus
        giscusConfig: {
            // Visit the link below, and follow the steps in the 'configuration' section
            // https://giscus.app/
            repo        : process.env.NEXT_PUBLIC_GISCUS_REPO,
            repositoryId: process.env.NEXT_PUBLIC_GISCUS_REPOSITORY_ID,
            category    : process.env.NEXT_PUBLIC_GISCUS_CATEGORY,
            categoryId  : process.env.NEXT_PUBLIC_GISCUS_CATEGORY_ID,
            mapping     : 'pathname', // supported options: pathname, url, title
            reactions   : '1', // Emoji reactions: 1 = enable / 0 = disable
            // Send discussion metadata periodically to the parent window: 1 = enable / 0 = disable
            metadata    : '0',
            // theme example: light, dark, dark_dimmed, dark_high_contrast
            // transparent_dark, preferred_color_scheme, custom
            theme       : 'light',
            // theme when dark mode
            darkTheme   : 'transparent_dark',
            // If the theme option above is set to 'custom`
            // please provide a link below to your custom theme css file.
            // example: https://giscus.app/themes/custom_example.css
            themeURL    : '',
        },
        utterancesConfig: {
            // Visit the link below, and follow the steps in the 'configuration' section
            // https://utteranc.es/
            repo     : process.env.NEXT_PUBLIC_UTTERANCES_REPO,
            issueTerm: '', // supported options: pathname, url, title
            label    : '', // label (optional): Comment 💬
            // theme example: github-light, github-dark, preferred-color-scheme
            // github-dark-orange, icy-dark, dark-blue, photon-dark, boxy-light
            theme    : '',
            // theme when dark mode
            darkTheme: '',
        },
        disqusConfig: {
            // https://help.disqus.com/en/articles/1717111-what-s-a-shortname
            shortname: process.env.NEXT_PUBLIC_DISQUS_SHORTNAME,
        },
    },
    store: {
        categories: [
            {
                id      : 'engineers',
                name    : 'Engineers',
                featured: [
                    {
                        name    : 'ZSA Ergodox Mark 1 Moonlander',
                        href    : Gadgets.ZSAErgodoxMoonlander,
                        imageSrc: '/images/blog/moonlander.jpg',
                        imageAlt: 'moonlander',
                        path    : '/resources/engineers/books/zsa-ergodox-mark-1-moonlander',
                    },
                    {
                        name    : 'levels.fyi',
                        href    : UsefulServices.LevelsFYI,
                        imageSrc: '/images/blog/levels-fyi.png',
                        imageAlt: 'levels.fyi',
                        path    : '/resources/engineers/books/zsa-ergodox-mark-1-moonlander',
                    },
                ],
                sections: [
                    {
                        id         : 'books',
                        name       : 'Books',
                        breadcrumbs: [
                            { id: 1, name: 'Engineers', href: '/resources/engineers' },
                            { id: 2, name: 'Books', href: '/resources/engineers/books' },
                        ],
                        items: [
                            {
                                name       : 'Cracking the Coding Interview',
                                href       : AmazonAffiliateLinks.CrackingTheCodingInterview,
                                path       : 'cracking-the-coding-interview',
                                tags       : [],
                                price      : '$24',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Books', href: '/resources/engineers/books' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/cracking-the-coding-interview-1.png',
                                        alt: 'cracking-the-coding-interview-1',
                                    },
                                    {
                                        src: '/images/blog/cracking-the-coding-interview-1.png',
                                        alt: 'cracking-the-coding-interview-2',
                                    },
                                    {
                                        src: '/images/blog/cracking-the-coding-interview-1.png',
                                        alt: 'cracking-the-coding-interview-3',
                                    },
                                    {
                                        src: '/images/blog/cracking-the-coding-interview-1.png',
                                        alt: 'cracking-the-coding-interview-4',
                                    },
                                ],
                                description:
                                'Now in the 6th edition, Cracking the Coding Interview gives you the interview preparation you need to get the top software developer jobs. This is a deeply technical book and focuses on the software engineering skills to ace your interview. The book is over 700 pages and includes 189 programming interview questions and answers, as well as other advice.',
                                highlights: [
                                    '189 programming interview questions, ranging from the basics to the trickiest algorithm problems.',
                                    'A walk-through of how to derive each solution, so that you can learn how to get there yourself.',
                                    'Hints on how to solve each of the 189 questions, just like what you would get in a real interview.',
                                    "Five proven strategies to tackle algorithm questions, so that you can solve questions you haven't seen.",
                                    'Extensive coverage of essential topics, such as big O time, data structures, and core algorithms.',
                                    'A behind the scenes look at how top companies like Google and Facebook hire developers.',
                                    'Techniques to prepare for and ace the soft side of the interview: behavioral questions.',
                                    'For interviewers and companies: details on what makes a good interview question and hiring process.',
                                ],
                                details:
                                'Cracking the Coding Interview, 6th Edition is here to help you through this process, teaching you what you need to know and enabling you to perform at your very best. I\'ve coached and interviewed hundreds of software engineers. The result is this book.',
                            },
                            {
                                name       : 'The Pragmatic Programmer',
                                href       : AmazonAffiliateLinks.ThePragmaticProgrammer,
                                path       : 'the-pragmatic-programmer',
                                tags       : [],
                                price      : '$40',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Books', href: '/resources/engineers/books' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/pragmatic-programmer.png',
                                        alt: 'pragmatic-programmer',
                                    },
                                    {
                                        src: '/images/blog/pragmatic-programmer.png',
                                        alt: 'pragmatic-programmer',
                                    },
                                    {
                                        src: '/images/blog/pragmatic-programmer.png',
                                        alt: 'pragmatic-programmer',
                                    },
                                    {
                                        src: '/images/blog/pragmatic-programmer.png',
                                        alt: 'pragmatic-programmer',
                                    },
                                ],
                                description:
                                'The Pragmatic Programmer is one of those rare tech books you’ll read, re-read, and read again over the years. Whether you’re new to the field or an experienced practitioner, you’ll come away with fresh insights each and every time.',
                                highlights: [
                                    'Fight software rot',
                                    'Learn continuously',
                                    'Avoid the trap of duplicating knowledge',
                                    'Write flexible, dynamic, and adaptable code',
                                    'Harness the power of basic tools',
                                    'Avoid programming by coincidence',
                                    'Learn real requirements',
                                    'Solve the underlying problems of concurrent code',
                                    'Guard against security vulnerabilities',
                                    'Build teams of Pragmatic Programmers',
                                    'Take responsibility for your work and career',
                                    'Test ruthlessly and effectively, including property-based testing',
                                    'Implement the Pragmatic Starter Kit',
                                    'Delight your users',
                                ],
                                details:
                                'Written as a series of self-contained sections and filled with classic and fresh anecdotes, thoughtful examples, and interesting analogies, The Pragmatic Programmer illustrates the best approaches and major pitfalls of many different aspects of software development. Whether you’re a new coder, an experienced programmer, or a manager responsible for software projects, use these lessons daily, and you’ll quickly see improvements in personal productivity, accuracy, and job satisfaction. You’ll learn skills and develop habits and attitudes that form the foundation for long-term success in your career.',
                            },
                            {
                                name       : 'Zero to One',
                                href       : AmazonAffiliateLinks.ZeroToOne,
                                path       : 'zero-to-one',
                                tags       : ['startups', 'silicon-valley'],
                                price      : '$18.49',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Books', href: '/resources/engineers/books' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/zero-to-one.png',
                                        alt: 'zero-to-one',
                                    },
                                    {
                                        src: '/images/blog/zero-to-one.png',
                                        alt: 'zero-to-one',
                                    },
                                    {
                                        src: '/images/blog/zero-to-one.png',
                                        alt: 'zero-to-one',
                                    },
                                    {
                                        src: '/images/blog/zero-to-one.png',
                                        alt: 'zero-to-one',
                                    },
                                ],
                                description:
                                `The great secret of our time is that there are still uncharted frontiers to explore and new inventions to create. In Zero to One, legendary entrepreneur and investor Peter Thiel shows how we can find singular ways to create those new things.

                                Thiel begins with the contrarian premise that we live in an age of technological stagnation, even if we’re too distracted by shiny mobile devices to notice. Information technology has improved rapidly, but there is no reason why progress should be limited to computers or Silicon Valley. Progress can be achieved in any industry or area of business. It comes from the most important skill that every leader must master: learning to think for yourself.
                                
                                Doing what someone else already knows how to do takes the world from 1 to n, adding more of something familiar. But when you do something new, you go from 0 to 1. The next Bill Gates will not build an operating system. The next Larry Page or Sergey Brin won’t make a search engine. Tomorrow’s champions will not win by competing ruthlessly in today’s marketplace. They will escape competition altogether, because their businesses will be unique.`,
                                highlights: [
                                    '#1 NEW YORK TIMES BESTSELLER • “This book delivers completely new and refreshing ideas on how to create value in the world.”—Mark Zuckerberg, CEO of Meta',
                                    '“Peter Thiel has built multiple breakthrough companies, and Zero to One shows how.”—Elon Musk, CEO of SpaceX and Tesla',
                                ],
                                details:
                                'Zero to One presents at once an optimistic view of the future of progress in America and a new way of thinking about innovation: it starts by learning to ask the questions that lead you to find value in unexpected places.',
                            },
                            {
                                name       : 'Clean Code',
                                href       : AmazonAffiliateLinks.CleanCode,
                                path       : 'clean-code',
                                tags       : [],
                                price      : '$18.49',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Books', href: '/resources/engineers/books' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/clean-code.png',
                                        alt: 'clean-code',
                                    },
                                    {
                                        src: '/images/blog/clean-code.png',
                                        alt: 'clean-code',
                                    },
                                    {
                                        src: '/images/blog/clean-code.png',
                                        alt: 'clean-code',
                                    },
                                    {
                                        src: '/images/blog/clean-code.png',
                                        alt: 'clean-code',
                                    },
                                ],
                                description:
                                `Even bad code can function. But if code isn’t clean, it can bring a development organization to its knees. Every year, countless hours and significant resources are lost because of poorly written code. But it doesn’t have to be that way.

                                Noted software expert Robert C. Martin, presents a revolutionary paradigm with Clean Code: A Handbook of Agile Software Craftsmanship. Martin, who has helped bring agile principles from a practitioner’s point of view to tens of thousands of programmers, has teamed up with his colleagues from Object Mentor to distill their best agile practice of cleaning code “on the fly” into a book that will instill within you the values of software craftsman, and make you a better programmer―but only if you work at it.
                                
                                What kind of work will you be doing? You’ll be reading code―lots of code. And you will be challenged to think about what’s right about that code, and what’s wrong with it. More importantly you will be challenged to reassess your professional values and your commitment to your craft.  
                                
                                Clean Codeis divided into three parts. The first describes the principles, patterns, and practices of writing clean code. The second part consists of several case studies of increasing complexity. Each case study is an exercise in cleaning up code―of transforming a code base that has some problems into one that is sound and efficient. The third part is the payoff: a single chapter containing a list of heuristics and “smells” gathered while creating the case studies. The result is a knowledge base that describes the way we think when we write, read, and clean code.`,
                                highlights: [
                                    'How to tell the difference between good and bad code',
                                    'How to write good code and how to transform bad code into good code',
                                    'How to create good names, good functions, good objects, and good classes',
                                    'How to format code for maximum readability',
                                    'How to implement complete error handling without obscuring code logic',
                                    'How to unit test and practice test-driven development',
                                    'What “smells” and heuristics can help you identify bad code',

                                ],
                                details:
                                'This book is a must for any developer, software engineer, project manager, team lead, or systems analyst with an interest in producing better code.',
                            },
                            {
                                name       : 'The Hidden Language of Computer Hardware',
                                href       : AmazonAffiliateLinks.TheHiddenLanguageOfComputerHardware,
                                path       : 'the-hidden-language-of-computer-hardware',
                                tags       : [],
                                price      : '$24',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Books', href: '/resources/engineers/books' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/hidden-language-of-computer-hardware.png',
                                        alt: 'hidden-language-of-computer-hardware',
                                    },
                                    {
                                        src: '/images/blog/hidden-language-of-computer-hardware.png',
                                        alt: 'hidden-language-of-computer-hardware',
                                    },
                                    {
                                        src: '/images/blog/hidden-language-of-computer-hardware.png',
                                        alt: 'hidden-language-of-computer-hardware',
                                    },
                                    {
                                        src: '/images/blog/hidden-language-of-computer-hardware.png',
                                        alt: 'hidden-language-of-computer-hardware',
                                    },
                                ],
                                description:
                                `What do flashlights, the British invasion, black cats, and seesaws have to do with computers? In CODE, they show us the ingenious ways we manipulate language and invent new means of communicating with each other. And through CODE, we see how this ingenuity and our very human compulsion to communicate have driven the technological innovations of the past two centuries.
                                Using everyday objects and familiar language systems such as Braille and Morse code, author Charles Petzold weaves an illuminating narrative for anyone who’s ever wondered about the secret inner life of computers and other smart machines.
                                It’s a cleverly illustrated and eminently comprehensible story—and along the way, you’ll discover you’ve gained a real context for understanding today’s world of PCs, digital media, and the Internet. No matter what your level of technical savvy, CODE will charm you—and perhaps even awaken the technophile within.`,
                                highlights: [],
                                details   : '',
                            },
                        ],
                    },
                    {
                        id         : 'online-tools',
                        name       : 'Online Tools',
                        breadcrumbs: [
                            { id: 1, name: 'Engineers', href: '/resources/engineers' },
                            { id: 2, name: 'Online Tools', href: '/resources/engineers/online-tools' },
                        ],
                        items: [
                            {
                                name       : 'Notion',
                                href       : UsefulServices.Notion,
                                path       : 'notion',
                                tags       : ['interviews', 'note-taking', 'brainstorming'],
                                price      : 'Free',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Online Tools', href: '/resources/engineers/online-tools' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/notion.png',
                                        alt: 'notion',
                                    },
                                    {
                                        src: '/images/blog/notion.png',
                                        alt: 'notion',
                                    },
                                    {
                                        src: '/images/blog/notion.png',
                                        alt: 'notion',
                                    },
                                    {
                                        src: '/images/blog/notion.png',
                                        alt: 'notion',
                                    },
                                ],
                                description:
                                'Notion is a single space where you can think, write, and plan. Capture thoughts, manage projects, or even run an entire company — and do it exactly the way you want.',
                                highlights: [],
                                details   : 'I\'ve used Notion for a variety of things, including interview preparation, trip planning, or just simply jotting down thoughts. The app is truly notes on steroids, with support for a variety of custom API integrations and various ways for visualizing data.',
                            },
                            {
                                name       : 'AlgoExpert',
                                href       : UsefulServices.AlgoExpert,
                                path       : 'algoexpert',
                                tags       : ['interviews', 'programming'],
                                price      : '$119',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Books', href: '/resources/engineers/online-tools' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/algoexpert.png',
                                        alt: 'algoexpert',
                                    },
                                    {
                                        src: '/images/blog/algoexpert.png',
                                        alt: 'algoexpert',
                                    },
                                    {
                                        src: '/images/blog/algoexpert.png',
                                        alt: 'cracking-the-coding-interview-3',
                                    },
                                    {
                                        src: '/images/blog/algoexpert.png',
                                        alt: 'algoexpert',
                                    },
                                ],
                                description:
                                'AlgoExpert is a paid platform where software engineers can practice technical interview questions. If you want to ace the coding interviews, being well-versed in all common data structures and popular problem-solving methods is paramount. With 160 questions spanning 15 categories and 4 difficulty levels, they\'ve got you covered. Personally, I\'ve found that AlgoExpert is worth it if you\'re interested in a quick refresher on commonly asked algorithms questions, or are new to algorithms and want good step-by-step explanations.',
                                highlights: [
                                    '160 hand-picked questions',
                                    '100+ hours of video explanations',
                                    'Data structures crash course',
                                    'Solutions in 9 languages',
                                    'Feature-rich coding workspace',
                                    'Space-time complexity analyses',
                                ],
                                details:
                                'Master the essential algorithms and data structures that are tested in coding interviews. Join the 50,000+ software developers who\'ve used our platform to land their dream jobs.',
                            },
                            {
                                name       : 'Levels.fyi',
                                href       : UsefulServices.LevelsFYI,
                                path       : 'levels-fyi',
                                tags       : ['interviews', 'compensation'],
                                price      : 'Free',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Online Tools', href: '/resources/engineers/online-tools' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/levels-fyi.png',
                                        alt: 'levels-fyi',
                                    },
                                    {
                                        src: '/images/blog/levels-fyi.png',
                                        alt: 'levels-fyi',
                                    },
                                    {
                                        src: '/images/blog/levels-fyi.png',
                                        alt: 'levels-fyi',
                                    },
                                    {
                                        src: '/images/blog/levels-fyi.png',
                                        alt: 'levels-fyi',
                                    },
                                ],
                                description:
                                'Levels is a website that provides salary information for software engineers at major tech companies. The site includes data on base salaries, bonuses, and equity for employees at different levels within each company. Users can anonymously upload their salaries, and this information is made available to other users. This information can be helpful for job seekers who are trying to negotiate their salaries, as well as for current employees who are considering changing jobs. In addition, the site includes information on the cost of living in different cities, which can be helpful for those who are looking to relocate. The site also aggregates anonymous job satisfaction data and reviews from actual employees, which can be valuable when considering whether to make a move to a new company. Levels.fyi is a valuable resource for anyone interested in understanding the software engineering job market.',
                                highlights: [],
                                details   : '',
                            },
                            {
                                name       : 'Team Blind',
                                href       : UsefulServices.TeamBlind,
                                path       : 'team-blind',
                                tags       : ['interviews', 'recruiting', 'compensation'],
                                price      : 'Free',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Online Tools', href: '/resources/engineers/online-tools' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/team-blind.png',
                                        alt: 'team-blind',
                                    },
                                    {
                                        src: '/images/blog/team-blind.png',
                                        alt: 'team-blind',
                                    },
                                    {
                                        src: '/images/blog/team-blind.png',
                                        alt: 'team-blind',
                                    },
                                    {
                                        src: '/images/blog/team-blind.png',
                                        alt: 'team-blind',
                                    },
                                ],
                                description:
                                'Blind is a popular app among software engineers, as it provides a great way to learn more about different companies and their cultures. The app allows users to anonymously post and view messages about their experiences, both good and bad. This can be a valuable way for engineers to get an inside look at different organizations before applying for jobs or internships. Additionally, Blind can be a useful tool for networking and building relationships with others in the industry, as by the time of this post there are over 6 million users. Whether you\'re looking for advice, looking to vent about a frustrating experience, or just wanting to chat with like-minded people, Blind is definitely worth checking out. Personally, I’ve used Blind to better understand the kind of company culture I might be coming into, and it’s proven to be very useful.',
                                highlights: [],
                                details   : '',
                            },
                        ],
                    },
                    {
                        id         : 'gadgets',
                        name       : 'Gadgets',
                        breadcrumbs: [
                            { id: 1, name: 'Engineers', href: '/resources/engineers' },
                            { id: 2, name: 'Gadgets', href: '/resources/engineers/gadgets' },
                        ],
                        items: [
                            {
                                name       : 'ZSA Ergodox Mark 1 Moonlander',
                                href       : Gadgets.ZSAErgodoxMoonlander,
                                path       : 'zsa-ergodox-mark-1-moonlander',
                                tags       : ['moonlander', 'ergonomic', 'keyboards'],
                                price      : '$365',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Gadgets', href: '/resources/engineers/gadgets' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/moonlander.jpg',
                                        alt: 'moonlander-image.avif',
                                    },
                                    {
                                        src: '/images/blog/moonlander.jpg',
                                        alt: 'moonlander-image.avif',
                                    },
                                    {
                                        src: '/images/blog/moonlander.jpg',
                                        alt: 'moonlander-image.avif',
                                    },
                                    {
                                        src: '/images/blog/moonlander.jpg',
                                        alt: 'moonlander-image.avif',
                                    },
                                ],
                                description:
                                'The ZSA Moonlander is a hot-swappable fully split keyboard with a columnar-staggered key layout. It\'s exceptionally customizable and lets you change the backlighting, adjust incline settings, and set macros. It\'s also hot-swappable, so you can even change the switches.',
                                highlights: [
                                    'Portable: carrying case included.',
                                    'Modular: Remove or swap any part.',
                                    'Fully backlit: RGB LEDs with smart animations',
                                    'Mechanical: Good old-fashioned keyswitches.',
                                    'Gamer-friendly: Left side can fly solo.',
                                    'Built to last: Two-year warranty. No fine print.',
                                ],
                                details: '',
                            },
                            {
                                name       : 'The Kinesis Freestyle Edge RGB',
                                href       : AmazonAffiliateLinks.KinesisEdge,
                                path       : 'kinesis-freestyle-edge-rgb',
                                tags       : ['keyboards', 'ergonomic'],
                                price      : '$199',
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Gadgets', href: '/resources/engineers/gadgets' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/kinesis-keyboard.png',
                                        alt: 'moonlander-image.avif',
                                    },
                                    {
                                        src: '/images/blog/kinesis-keyboard.png',
                                        alt: 'moonlander-image.avif',
                                    },
                                    {
                                        src: '/images/blog/kinesis-keyboard.png',
                                        alt: 'moonlander-image.avif',
                                    },
                                    {
                                        src: '/images/blog/kinesis-keyboard.png',
                                        alt: 'moonlander-image.avif',
                                    },
                                ],
                                description: 'The Kinesis Freestyle Edge RGB is a mechanical keyboard that has been designed with the needs of software engineers in mind. The keyboard has a split layout that allows for greater comfort and ergonomics while coding for long periods of time. In addition, the Kinesis Freestyle Edge RGB has a 20-key rollover and anti-ghosting features that make it perfect for fast-paced coding sessions. The Kinesis Freestyle Edge RGB also features fully programmable keys, allowing software engineers to customize the keyboard to their specific needs. The keyboard also includes an OLED Smart Display that provides real-time feedback on vital statistics such as CPU usage, memory usage, and more. For software engineers who code all day, the Kinesis Freestyle Edge RGB is the perfect keyboard.',
                                highlights : [
                                    'Versatile split design great for typing and gaming: move the right module out of the way and bring your mouse in close for improved endurance and more precise aim (eSports proven). Rotate the left module for optimal key coverage or to squeeze into a tight space at a LAN. Or split the modules up to 20 inches and put your stream mic, HOTAS, or mouse in the middle for easy access. Separate the modules to shoulder-width for an ergonomic typing posture and add the lift kit tenting accessory to reduce forearm strain. The detachable Palm Supports now include all-new thick cushioned palm pads for even more comfort.',
                                    '100% Mechanical switches for maximum performance: genuine Cherry MX Blue mechanical switches (clicky, tactile feedback) offer professional-grade responsiveness and unmatched durability (50M clicks). Choose the switch that\'s right for you.',
                                    'Immersive RGB lighting for a signature look: 16.8M Color per-key RGB Backlighting with 10 customizable effects like wave, spectrum, rebound, pulse, rain and much more. Install lighting expansion pack 1 to add Dual layer lighting and two-tone effects.',
                                    'All 95 keys are fully programmable for complete customization: Use the dedicated SmartSet key for convenient on-the-fly Remaps and macros, or use the all-new SmartSet app for special actions or to customize lighting effects. 9 available profiles with dual layers, powerful macros, quick Remaps, 9 game keys, 1ms response time, NKRO, game mode, & much more. All profiles and settings save directly to the keyboard\'s 4MB onboard memory for Tournament play.',
                                    '100% plug-and-play: Compatible with Windows, Mac, Linux, and chrome (1 full-size USB port required). No special drivers required. Rgb SmartSet app for Windows and Mac available for download.',
                                ],
                                details: '',
                            },
                            {
                                name       : 'Nexigo Web Camera',
                                href       : AmazonAffiliateLinks.NexigoWebcam,
                                path       : 'nexigo-webcam',
                                price      : '$39.99',
                                tags       : ['video-calls'],
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Gadgets', href: '/resources/engineers/gadgets' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/nexigo-webcam.png',
                                        alt: 'nexigo-webcam',
                                    },
                                    {
                                        src: '/images/blog/nexigo-webcam.png',
                                        alt: 'nexigo-webcam',
                                    },
                                    {
                                        src: '/images/blog/nexigo-webcam.png',
                                        alt: 'nexigo-webcam',
                                    },
                                    {
                                        src: '/images/blog/nexigo-webcam.png',
                                        alt: 'nexigo-webcam',
                                    },
                                ],
                                description: 'One of the worst things you can do during an interview is appear blurry or barely visible during on interview. Check out this NexiGo N60 1080P Web Camera; it comes with an HD Webcam with Microphone, Software Control & Privacy Cover, USB Computer Camera, 110-degree FOV, Plug and Play, for Zoom/Skype/Teams, Conferencing and Video Calling, and more!',
                                highlights : [
                                    '【Full HD 1080P Webcam】Powered by a 1080p FHD two-MP CMOS, the NexiGo N60 Webcam produces exceptionally sharp and clear videos at resolutions up to 1920 x 1080 with 30fps. The 3.6mm glass lens provides a crisp image at fixed distances and is optimized between 19.6 inches to 13 feet, making it ideal for almost any indoor use.',
                                    '【USB Webcam with Privacy Protection Cover】The privacy cover blocks the lens when the webcam is not in use. It\'s perfect to help provide security and peace of mind to anyone, from individuals to large companies. It also helps to protect the lens from dirt and debris to ensure your video remains clear for the life of the camera.',
                                    '【Built-in Noise-Cancelling Microphone】The built-in noise-canceling microphone reduces ambient noise to enhance the sound quality of your video. Great for Zoom / Facetime / Video Calling / OBS / Twitch / Facebook / YouTube / Conferencing / Gaming / Streaming / Recording / Online School.',
                                    '【Plug and Play】The fixed focal length lens captures high-definition video at a wide-angle of up to 110°, making it ideal for presentations. Just plug the USB into your computer and you are good to go! A convenient mounting clip allows the webcam to be placed on any monitor / laptop / TV/ tripod.',
                                    'Wide Compatibility】Works with USB 2.0/3.0, no additional drivers required. Ready to use in approximately one minute or less on any compatible device. Compatible with Mac OS X 10.7 and higher / Windows 7, 8, 10 & 11 / Android 4.0 or higher / Linux 2.6.24 / Chrome OS 29.0.1547 / Ubuntu Version 10.04 or above.',
                                ],
                                details: 'I\'ve used this webcam for years, and it\'s proven very beneficial for interviews, as it\'s highly adjustable and can fit almost anywhere.',
                            },
                            {
                                name       : 'Yeti Stereo Microphone',
                                href       : AmazonAffiliateLinks.YetiMicrophone,
                                path       : 'yeti-microphone',
                                price      : '$89.99',
                                tags       : ['video-calls'],
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Gadgets', href: '/resources/engineers/gadgets' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/yeti-microphone.png',
                                        alt: 'yeti-microphone',
                                    },
                                    {
                                        src: '/images/blog/yeti-microphone.png',
                                        alt: 'yeti-microphone',
                                    },
                                    {
                                        src: '/images/blog/yeti-microphone.png',
                                        alt: 'yeti-microphone',
                                    },
                                    {
                                        src: '/images/blog/yeti-microphone.png',
                                        alt: 'yeti-microphone',
                                    },
                                ],
                                description: 'In addition to having a clearly visible face when on a video call, it\'s also important to have good audio quality. A few years back, I purchased the Yeti Stereo Microphone, and haven\'t gone back since. My coworkers always mention how nice it is to hear my voice so clearly relative to others, allowing me to be more effective in my communication.',
                                highlights : [
                                    'Tri-capsule array - 3 condesner capsules can record almost any situation',
                                    'Multiple pattern selection - cardioid, bidirectional, omnidirectional & stereo',
                                    'Gain control, mute button, zero-latency headphone output. Signal to noise: 100 dB',
                                    'Perfect for vocals, musical instruments, podcasting, voiceovers, interviews, field recordings, conference calls',
                                ],
                                details: '',
                            },
                            {
                                name       : 'Soundance Laptop Stand',
                                href       : AmazonAffiliateLinks.LaptopStand,
                                path       : 'soundance-laptop-stand',
                                price      : '$28',
                                tags       : ['desk-efficiency'],
                                breadcrumbs: [
                                    { id: 1, name: 'Engineers', href: '/resources/engineers' },
                                    { id: 2, name: 'Gadgets', href: '/resources/engineers/gadgets' },
                                ],
                                images: [
                                    {
                                        src: '/images/blog/soundance-laptop-stand.jpg',
                                        alt: 'soundance-laptop-stand',
                                    },
                                    {
                                        src: '/images/blog/soundance-laptop-stand.jpg',
                                        alt: 'soundance-laptop-stand',
                                    },
                                    {
                                        src: '/images/blog/soundance-laptop-stand.jpg',
                                        alt: 'soundance-laptop-stand',
                                    },
                                    {
                                        src: '/images/blog/soundance-laptop-stand.jpg',
                                        alt: 'soundance-laptop-stand',
                                    },
                                ],
                                description: '',
                                highlights : [
                                    'Suit for any laptop 10 to 15.6 inches - The laptop stand fits all laptops 10 to 15.6 inches, such as notebooks PC computers 10 inches, 11 inches, 12 inches, 13 inches, 13.3 inches, 14 inches, 15 inches, 15.4 inches, 15.6 inches, etc.',
                                    'Ergonomic design - The slim compact stand elevates your laptop by 6-inch to a perfect eye level and prevents you from hunching over your screen, which reduces neck and shoulder pain. Moreover, the edge of the stand is so smooth that protects you from being scratched.',
                                    'Cooling better and keep desktop organized - The ventilated elevator desk stand only has two holder hands but not a whole flat, so there is more space to enable the heat to vent and keep your notebook computer from overheating. Also, you can organize your office items such as keyboard and mouse under the thin stand.',
                                    'Detachable and simple installation - The laptop stand can be conveniently disassembled into 3 parts. And You can easily reassemble it again easily.',
                                    'Sturdy and portable - The office computer stand is totally made of thickened aluminum alloy, so the stand is sturdier and has less wobbly. The rubber on the holder hands sticks tightly and ensure your laptop stable on the stand. And the rubber feet prevent the stand from slipping on your desktop. Moreover, the stand is detachable and easy to install without tools, so the lightweight laptop stand is really portable.',
                                ],
                                details: '',
                            },
                        ],
                    },
                ],
            },
            {
                id      : 'recruiters',
                name    : 'Recruiters',
                featured: [],
                sections: [],
            },
        ],
        pages: [
        ],
    },
};
