import * as React from 'react';
import { Route } from 'react-router-dom';
import LayoutWrapper from '../components/layouts/LayoutWrapper';

const PortfolioRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <LayoutWrapper>
                        <Component {...props} />
                    </LayoutWrapper>
                );
            }}
        />
    );
};

export default PortfolioRoute;
