import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import SocialIcon from '../social-icons';
import profileImage from '../../images/face.png';
import { siteMetadata } from '../../data/siteMetadata';
import HelmetComponent from '../helmet/HelmetComponent';

const AuthorLayout = () => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.set({ page: history.location.pathname });
        ReactGA.pageview(history.location.pathname);
    }, []);

    return (
        <>
            <HelmetComponent
                title="About | tallmarmaladeporpoise"
                description="Here you will find information about the author and the motivation behind the site."
                URL="https://tallmarmaladeporpoise.io/about"
            />
            <div className="divide-y">
                <div className="pt-6 pb-8 space-y-2 md:space-y-5">
                    <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14">
                        About
                    </h1>
                </div>
                <div className="items-start space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
                    <div className="flex flex-col items-center pt-8 space-x-2">
                        <img
                            src={profileImage}
                            alt="avatar"
                            className="w-40 h-40 rounded-full"
                        />
                        <div className="flex pt-6 space-x-3">
                            <SocialIcon kind="linkedin" href={`${siteMetadata.linkedin}`} size={2} />
                            <SocialIcon kind="github" href={`${siteMetadata.github}`} size={2} />
                            <SocialIcon kind="mail" href={`mailto:${siteMetadata.email}`} size={2} />
                        </div>
                    </div>
                    <div className="pt-8 pb-8 prose text-gray-500 pb-2 max-w-none dark:text-gray-400 text-xl max-w-none xl:col-span-2">
                        {siteMetadata.about.descriptionOne}
                        <a
                            href={siteMetadata.about.hiringLink}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {siteMetadata.about.highlightText}
                        </a>
                        {siteMetadata.about.hiringFollowUp}
                        <div className="pt-8 pb-8 prose text-gray-500 pb-2 max-w-none dark:text-gray-400 text-xl max-w-none">
                            {siteMetadata.about.passions}
                        </div>
                        <div className="pt-8 pb-8 prose text-gray-500 pb-2 max-w-none dark:text-gray-400 text-xl max-w-none ">
                            {siteMetadata.about.contact}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthorLayout;
