import * as React from 'react';
import { Switch } from 'react-router-dom';
import PortfolioRoute from './PortfolioRoute';
import AuthorLayout from '../components/layouts/AuthorLayout';
import FourZeroFourLayout from '../components/layouts/404Layout';

export const DEFAULT_ROUTE_PATHS: {[name: string]: string} = {
    HOME     : '/',
    ABOUT    : '/about',
    PROJECTS : '/projects',
    LESSONS  : '/lessons',
    BLOGS    : '/blog',
    TAGS     : '/tags',
    RESOURCES: '/resources',
    CONTACT  : '/contact',
    RESUME   : '/resume',
};

export const RouteDeclarations: React.FC = () => {

    return (
        <Switch>
            {/* About */}
            <PortfolioRoute exact path={DEFAULT_ROUTE_PATHS.ABOUT} component={AuthorLayout} />
            {/* Blog */}
            <PortfolioRoute exact path={DEFAULT_ROUTE_PATHS.HOME} component={AuthorLayout} />
            {/* <PortfolioRoute exact path={DEFAULT_ROUTE_PATHS.BLOGS} component={BlogPreview} /> */}
            {/* <PortfolioRoute path={`${DEFAULT_ROUTE_PATHS.BLOGS}/page/*`} component={BlogPreview} /> */}
            {/* <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.BLOGS}/*`} component={Blog} /> */}
            {/* Tag */}
            {/* <PortfolioRoute exact path={DEFAULT_ROUTE_PATHS.TAGS} component={Tags} /> */}
            {/* <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.TAGS}/*`} component={TagList} /> */}
            {/* Resources */}
            {/* <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.RESOURCES}`} component={TechStore} />
            <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.RESOURCES}/engineers`} component={TechStore} />
            <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.RESOURCES}/engineers`} component={TechStore} />
            <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.RESOURCES}/engineers/books`} component={ProductList} />
            <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.RESOURCES}/engineers/online-tools`} component={ProductList} />
            <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.RESOURCES}/engineers/gadgets`} component={ProductList} />
            <PortfolioRoute path={`${DEFAULT_ROUTE_PATHS.RESOURCES}/*`} component={ProductOverview} /> */}
            {/* Lessons */}
            {/* <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.LESSONS}`} component={LessonPreview} />
            <PortfolioRoute path={`${DEFAULT_ROUTE_PATHS.LESSONS}/page/*`} component={LessonPreview} /> */}
            {/* <PortfolioRoute exact path={`${DEFAULT_ROUTE_PATHS.LESSONS}/*`} component={Blog} /> */}
            {/* 404 */}
            <PortfolioRoute exact path="*" component={FourZeroFourLayout} />

        </Switch>
    );
};
