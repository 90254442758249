import * as React from 'react';
import ReactGA from 'react-ga';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Github } from './github.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactGAEvents } from '../../utils/Constants';

// Icons taken from: https://simpleicons.org/

const components = {
    mail    : Mail,
    github  : Github,
    linkedin: Linkedin,
    twitter : Twitter,
};

const SocialIcon = ({ kind, href, size = 8 }) => {
    if (!href || (kind === 'mail' && !/^mailto:\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(href))) { return null; }

    const SocialSvg = components[kind];

    return (
        <a
            className={`text-sm text-gray-500 transition hover:text-gray-600 h-${size} w-${size}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '1.8em' }}
            href={href}
            onClick={() => {
                ReactGA.event({
                    category: ReactGAEvents.ExternalLink,
                    action  : `Clicked External Link ${href}`,
                });
            }}
        >
            <span className="sr-only">{kind}</span>
            <SocialSvg
                className={`fill-current text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 h-${size} w-${size}`}
            />
        </a>
    );
};

export default SocialIcon;
