const headerNavLinks = [
    { href: '', title: '' },
    // { href: '/blog', title: 'Blog' },
    // { href: '/resources', title: 'Resources' },
    // { href: '/tags', title: 'Tags' },
    { href: '/about', title: 'About' },
    // { href: '/travel', title: 'Travel' },
];

export default headerNavLinks;
