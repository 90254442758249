import React, { useState, useEffect, useMemo } from 'react';
import { Language } from '../utils/Entities';
import { DEFAULT_LANGUAGE, LANGUAGE_SET, getLanguageByCode } from '../utils/Constants';

interface UIContextProviderProps extends React.Props<any> {}
interface UIContextAction {
  setDrawerOpen: (open: boolean)=> void;
  setCurrentLanguage: (language: Language)=> void;
  setDarkMode: (setDarkMode: boolean)=> void;
}
interface UIContextState {
  drawerOpen: boolean;
  currentLanguage: Language;
  darkMode: boolean;
}
export interface UIContext {
  state: UIContextState;
  actions: UIContextAction;
}

export const globalUIContext = React.createContext<any>(null);

export const UIContextProvider = (props: UIContextProviderProps) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
    const [currentLanguage, setCurrentLanguage] = useState<Language>(DEFAULT_LANGUAGE);
    const [darkMode, setDarkMode] = useState<boolean>(localStorage.theme === 'dark');

    useEffect(() => {
        const languagePreference = localStorage.getItem('lang_pref');
        if (languagePreference !== '' && languagePreference !== undefined && (languagePreference) !== null) {
            const preference = getLanguageByCode(LANGUAGE_SET, languagePreference);
            if (preference) {
                setCurrentLanguage(preference);
            }
        }
    }, []);

    useEffect(() => {
        if (darkMode) {
            localStorage.theme = 'dark';
            document.documentElement.classList.add('dark');
            setDarkMode(true);
        } else {
            localStorage.theme = 'light';
            document.documentElement.classList.remove('dark');
            setDarkMode(false);
        }

    }, [darkMode]);

    const values: UIContextState = {
        drawerOpen,
        currentLanguage,
        darkMode,
    };

    const valueObject = useMemo(() => {
        return {
            state  : values,
            actions: {
                setDrawerOpen,
                setCurrentLanguage,
                setDarkMode,
            },
        };
    }, [values]);

    return (
        <globalUIContext.Provider
            value={valueObject}
        >
            {props.children}
        </globalUIContext.Provider>
    );
};
